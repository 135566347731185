<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Klienta kontaktpersonas
      </h3>
    </template>
    <template v-slot:buttons>
      <div class="flex gap-3">
        <template v-if="contacts.length > 1">
          <Input name="search" placeholder="Meklēt" v-model="localSearch" :showPlaceholder="false" />

          <Button :icon="showFullList ? 'chevron-up' : 'chevron-down'" @click="showFullList = !showFullList" />
        </template>

        <template v-if="this.$userCan('manage_customers')">
          <Button icon="plus" @click="showForm('AddCustomerContactForm')" />
        </template>
      </div>

    </template>
    <template v-slot:content>

      <template v-if="!displayForm('AddCustomerContactForm')">
        <template v-if="contacts.length > 0">
          <div class="flex flex-col w-full">
            <ShowCustomerContact v-for="contact in allContacts" :key="contact.id" :contact="contact" />
          </div>
        </template>

        <template v-else>
          <div class="w-full">
            <p class="text-center text-lg text-gray-400 mb-3">Klientam nav pievienotas filiāles</p>
            <button @click="showForm('AddBranch')" type="button" class="block mx-auto items-center px-3 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 hover:bg-gray-100 transition duration-500 ease-in-out">
              Pievienot
            </button>
          </div>
        </template>
      </template>

      <template v-if="displayForm('AddCustomerContactForm') && this.$userCan('manage_customers')">
        <AddNewCustomerContact :customer="customer" />
      </template>

    </template>
  </item-card>
</template>

<script>
import {mapGetters} from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import Input from "@/components/Components/Input"
import ShowCustomerContact from "@/components/Customer/CustomerContacts/ShowCustomerContact"
import AddNewCustomerContact from "@/components/Customer/CustomerContacts/AddNewCustomerContact"

export default {
  name: "AllCustomerContacts",
  components: {
    ItemCard,
    Input,
    ShowCustomerContact,
    AddNewCustomerContact,
  },
  props: ['contacts'],
  mounted() {
      this.$store.dispatch('clearEditableCustomerContact')
      this.showFullList ? this.allContacts = [...this.contacts] : this.allContacts = [...this.contacts].slice(0, 1)
  },
  data() {
    return {
      allContacts: [],
      localSearch: "",
      showFullList: false,
    }
  },
  computed: {
    ...mapGetters({
      customer: 'customer',
      formsForDisplay: 'formsForDisplay',
    })
  },
  watch: {
    contacts() {
      this.showFullList ? this.allContacts = [...this.contacts] : this.allContacts = [...this.contacts].slice(0, 1)
    },
    showFullList() {
      this.showFullList ? this.allContacts = [...this.contacts] : this.allContacts = [...this.contacts].slice(0, 1)
    },
    localSearch() {
      this.allContacts = this.contacts.filter(contact => {
        if (contact.email.toLowerCase().includes(this.localSearch.toLowerCase())
        || contact.phone.toLowerCase().includes(this.localSearch.toLowerCase())
        || contact.name.toLowerCase().includes(this.localSearch.toLowerCase())) {
          return contact;
        }
      })
    }
  },
  methods: {
    showDivider(index) {
      return (this.contacts.length - 1) !== index
    },
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
  }
}
</script>

<style>

</style>